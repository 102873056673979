<template>
  <div class="page sub-nav-bar-on-page">
    <div class="sub-nav-bar">
      <div class="sub-nav-button" @click="goto('gameInfo')" :class="{active: page === 'gameInfo'}">Game Info</div>
      <div class="sub-nav-button" @click="goto('gameRules')" :class="{active: page === 'gameRules'}">Game Rules</div>
      <div class="sub-nav-button" @click="goto('beginnersGuide')" :class="{active: page === 'beginnersGuide'}">Beginners Guide</div>
      <div class="sub-nav-button" @click="goto('media')" :class="{active: page === 'media'}">Media</div>
    </div>

    <GameInfo v-if="page === 'gameInfo'" />
    <GameRules v-if="page === 'gameRules'" />
    <BeginnersGuide v-if="page === 'beginnersGuide'" />
    <Media v-if="page === 'media'" />
  </div>
</template>

<script>
import GameInfo from '@/views/Info/GameInfo'
import GameRules from '@/views/Info/GameRules'
import BeginnersGuide from '@/views/Info/BeginnersGuide'
import Media from '@/views/Info/Media'

export default {
  name: 'Info',
  components: { Media, BeginnersGuide, GameRules, GameInfo },
  data () {
    return {
      activeTab: 0
    }
  },
  computed: {
    page () {
      return this.$route.params.page || 'gameInfo'
    }
  },
  methods: {
    goto (page) {
      if (this.$router.currentRoute.path !== '/info/' + page) {
        this.$router.push('/info/' + page)
      }
    }
  }
}
</script>
